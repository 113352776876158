/**
 * Typography
 *
 */

@mixin fontello-its() {
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

h3.pane-title {
  color: $wcm-dark-gray;
  margin-bottom: 12.5px;
}
