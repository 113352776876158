/**
 * Services
 *
 */


/*MED GRAPHICS DESIGN*/
 .portfolio{
 	float: left;
    padding: 40px 30px 40px 0px;
 }

 .portfolio-headers{
 	padding: 40px 0px 10px 0px;
 }

 .portfolio-container{
 }

.page-node-290 .node-type-service #main-content .panel-pane {
    border-bottom: 0px !important;
    }

.field-basic-table-table{
   > div{
      overflow-x: auto;
   }
}