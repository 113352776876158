/**
 * Branding
 *
 */

.brand__lockup img {
  width: auto;
  height: 74px;
  margin: 20px 0 10px;
}

/**
 * Primary Navigation
 *
 */

#primary-nav {
  @include breakpoint($sm) {
    line-height: 18px;
  }
}

#primary-nav .level-1 {
  @include breakpoint($sm) {
    width: 11%;
  }
}

// Security and Privacy
#primary-nav  .menu-mlid-1141 {
  @include breakpoint($sm) {
    width: 21%;
  }
}

/**
 * Drawer Nav
 *
 */

#drawer-nav .active-trail > a {
    color: #e87722;
}

#drawer-nav .menu-mlid-1140 .level-2 {
  @include breakpoint($sm) {
    width: 25%;
  }
}

// Hide Individual Services and Teams in the drawer
.menu-mlid-1140 .level-3,
.menu-mlid-1590 .level-3  {
  display: none;
}

/**
 * General Layout
 *
 */

.main-content {
  margin-bottom: 30px;
  @include breakpoint($md) {
    margin-bottom: 0;
  }
}

/**
 * Service Nodes
 *
 */

.pane-node-field-service-synonyms .field-label {
  display: inline;
  font-weight: 700;
}

.node-type-service #main-content {
  .panel-pane {
    border-bottom: 1px solid #efefef;
    padding-bottom: 5px;
    margin-top: 2rem;
  }
}

.node-type-service .information-sidebar {
  .panel-pane {
    margin-top: 1rem;
  }
}

/**
 * Get Help
 *
 */

.get-help {
  border-bottom: 1px solid #efefef;
  padding: 0 0 30px;
  margin-bottom: 30px;
  @include clearfix();
}

.get-help > *:last-child,
.get-help > *:last-child > *:last-child,
.get-help > *:last-child > *:last-child > *:last-child {
  margin: 0;
}


.walkup-directions {
  @include clearfix();
}

.walkup-map {
  @include breakpoint($sm) {
    width: 300px;
    float: left;
  }
}

.walkup-address {
  @include breakpoint($sm) {
    width: 310px;
    float: left;
    padding-left: 30px;
  }
}

.consult-description {
  @include clearfix();
}

.consult-description > p {
  @include breakpoint($sm) {
    width: 300px;
    float: left;
  }
}

.consult-services {
  @include breakpoint($sm) {
    width: 310px;
    float: left;
    padding-left: 30px;
  }
}

/**
 * Synonyms
 *
 */

.pane-node-field-service-synonyms {
  color: $wcm-med-gray;
  margin-bottom: 30px;
  font-size: 14px;
}

.pane-node-field-service-synonyms .pane-title {
  display: none;
}

.field-name-field-service-synonyms .field-label {
  padding-right: 5px;
}

.synonyms ul {
  display: inline;
  margin: 0;
  padding: 0;
}

.synonyms li {
  display: inline;
  margin: 0;
  padding: 0 10px 0 0;
}



/**
 * Status Notification
 *
 */

.service-status {
  text-align: center;
  border: 1px solid #ccc;
  padding: 3px 0;
  border-radius: 20px;
  &:after {
    content: '\25CF';
    font-size: 18px;
    padding-left: 5px;
  }
}

.status-alerts {
  font-size: 12px;
}

// Good
.service-status-0,
.service-status-Good {
  &::after{
    color: #62b53c;
  }
}

// Maintenance
.service-status-1,
.service-status-Maintenance{
  &::after {
    color: #c8d6a5;
  }
}

// Incident
.service-status-2,
.service-status-Incident{
  &::after {
    color: #ff9903;
  }
}

// Degradation
.service-status-3:after {
  color: #ffe703;
}

// Outage
.service-status-4:after {
  color: #fc412f;
}

// Status Alerts

.js .pane-alerts-panel-pane-1 {
  .view-content {
    display: none;
  }
}

.alert__total {
  color: #3787b0;
  cursor: pointer;
  padding-bottom: 5px;
  &:after {
    padding-left: 5px;
    content: '\e814';
    @include fontello-its();
  }
}

.alert__total.open {
  &:after {
    content: '\e82b';
  }
}

.service-status-list {
  border-bottom: 1px solid #efefef;
  padding: 0 0 0.75em;
  margin-bottom: 10px;
  @include clearfix();

  .service-status {
    width: 50%;
    float: right;
    line-height: 1.5;
    padding: 0;
    font-size: 13px;
    
    a{
      border: unset;
      color: unset;

      &:hover{
        color: $wcm-red;
      }
    }
  }

  &__title {
    width: 50%;
    float: left;
    line-height: 1.5;
    font-size: 13px;
  }
}

/**
 *  Alert Index
 *
 */

.alerts {
  padding: 30px 0;
  border-bottom: 1px solid #efefef;
}

.alert__title {
  //font-size: 20px;
  margin-bottom: 5px;
  a {
    color: $wcm-red;
  }
}

.pane-alerts-panel-pane-2 {
  @include breakpoint($sm) {
    position: relative;
  }
  .view-footer {
    @include breakpoint($sm) {
      position: absolute;
      top: 12px;
      right: 50px;
    }
  }
}

.item-list .pagination {
  margin: 30px 0;
}

/**
 * Sidebar Panes
 *
 */

.information-sidebar .pane-title {
  font-size: 18px;
  color: $wcm-dark-gray;
}

/**
 * Service Help Pane
 *
 */

.help {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 30px;
  @include clearfix();
  @include breakpoint($sm) {
    width: auto;
    margin: auto;
  }
  .icon {
    float: left;
    width: 25%;
    color: $wcm-bright-orange;
    font-size: 46px;
    &:before {
      margin-left: 0;
    }
  }
  .cta-icon {
    fill: $wcm-bright-orange;
    color: $wcm-dark-orange;
    margin-right: 10px;
  }
}

.help__info {
  float: left;
  width: 75%;
  padding: 5px 0 0;
  line-height: 1.7;
}

.help__title {
  font-size: 18px;
}

.help__action {
  color: $wcm-med-gray;
}

.help--email {
  margin-bottom: 0;
  .help__info {
    @include breakpoint($lg) {
      position: relative;
      top: 25px;
    }
  }
}

/**
 * Acronym Finder
 * Turning this off for now.
 */

.lookup-widget__help {
  color: $wcm-med-gray;
}

.lookup-widget {
  input {
    position: relative;
    width: 100%;
    padding: 5px;
    font-size: 13px
  }
}

.lookup-description {
  display: none;
}

.lookup-widget__result {
  display: none;
  position: absolute;
  z-index: 1000;
  background: #eee;
  border: 1px solid #ccc;
  padding: 1em;
  font-size: 13px;
}

.ui-autocomplete {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0px;

  .ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 8px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    border-top: 1px solid #ccc;
    font-size: 12px;

    &:first-child {
      border: none;
    }

    &.ui-state-hover, &.ui-state-active {
      color: #ffffff;
      text-decoration: none;
      background-color: #0088cc;
      border-radius: 0px;
      background-image: none;
    }
  }
}

/**
 *  ITS Glossary
 *
 */

.pane-acronyms-panel-pane-2 {
  color: $wcm-med-gray;
}

.pane-acronyms-panel-pane-2 {
  .views-exposed-form {
    background: none;
    padding: 10px 0;
  }
  .views-exposed-form .views-exposed-widget {
    select {
      color: $wcm-med-gray;
    }
    input {
      position: relative;
      width: 100%;
      padding: 0.5em;
      border: 1px solid #ccc;
      color: $wcm-med-gray;
      font-size: 13px;
    }
    .form-submit {
      width: 100px;
      margin: 5px 0 0 0;
      background: $wcm-dark-orange;
      color: #fff;
      text-transform: uppercase;
      font-weight: normal;
      border: none;
      font-size: 12px;
      &:hover {
        background: $wcm-bright-orange;
      }
    }
  }
  .view-acronyms .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 5px;
  }
}

.glossary {
  margin-bottom: 5px;
}

.glossary__term {
  font-weight: bold;
}

/**
 * ITS Glossary Index
 *
 */

.view-acronyms table {
  margin: 20px 0 0;
  tr.odd {
    background-color: #fff;
  }
  .views-field-title {
    font-weight: 700;
    padding: 10px;
    line-height: 1.4;
  }
  .views-field-field-acronym-definition {
    padding: 10px;
    line-height: 1.4;
  }
}

.view-acronyms .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 35px;
}

.view-acronyms .views-exposed-form label[for="edit-acronym"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

/**
 * Training & Guides
 *
 */

.guide {
  margin-bottom: 15px;
}

.guide--document:before {
  content: '\e81f';
  color: #333;
  margin-right: 5px;
  font-size: 24px;
  @include fontello-its();
}

.guide--video:before {
  content: '\e828';
}

.pane-training-panel-pane-2 {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.training__related-service,
.guide__related-service {
  padding-left: 10px;
  a {
    color: #b1b9bd;
  }
}


/**
 * News Teasers
 *
 */

 .view-teaser {
  border-bottom: 1px solid #efefef;
}

.view-teaser:last-child {
  border: none;
}

.teaser-title {
  font-size: 20px;
  margin: 0;
}

.teaser__image {
  @include breakpoint($sm) {
    float: right;
    height: auto;
    padding-left: 20px;
    margin-top: 20px;
  }

  @include breakpoint($md) {
    
  }

  img {
    width: 150px;
    height: 150px;
  }
  & a > img {
    border-radius: 0px;
  }
}

.teaser__detail {
  padding: 20px 0;
}

.featured-news-teaser .teaser__detail {
  padding: 1rem;
}

.teaser__service {
  font-size: 13px;
  margin-bottom: 10px;
}

//News Page
.page-news{
  // H1
  .panel-pane.pane-fieldable-panels-pane.pane-bundle-text{
    div.fieldable-panels-pane div h1{
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .pane-news-panel-pane-1{
    .view-id-news{
      .view-content{
        .view-teaser{

          .teaser-title{
            margin-bottom: .5em;
          }
          .post-date{
            color: $wcm-med-gray;
            font-family: $headings-font-family;
            font-size: .8em;
            font-weight: lighter;
            text-transform: uppercase;
            margin-bottom: .5em;
          }
          .views-field-field-news-category{
            a{
              color: $wcm-white;
              background: $wcm-dark-orange;
              padding: 3px 14px 4px 14px;
              border-radius: 15px;
              border-bottom: none;
              font-size: 14px;
            }
          }

        }
      }
    }
  }
}

// Individual News Post
.node-type-news-post{
  .pane-node-field-news-category{
    .field-news-category{
      li{
        margin: auto 10px 10px auto;
        display: inline-block;
        background: $wcm-dark-orange;
        padding: 0px 12px 2px 12px;
        border-radius: 15px;
          
        a{
          color: $wcm-white;
          border-bottom: none;
          font-size: 14px;

          &:hover{
            border-bottom: 1px solid $wcm-white;
          }
        }
      }
    }
  }
  .pane-node-field-news-posts{
    border: 1px solid $wcm-border-gray;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 20px;

    div{
      margin-left: 1em;
      a{
        display: table;
        &:before {
          content: "\2022  "; 
          color: $wcm-dark-gray;
          font-weight: bold;
        }
      }
    }
  }
}

.pane-news-panel-pane-5 {
  @include breakpoint($sm) {
    position: relative;
    margin: 30px 0;
  }
  .view-content {
    @include breakpoint($sm) {
      border: 1px solid #efefef;
      padding: 15px;
      margin: 20px 0 0;
    }
  }
  .view-footer {
    @include breakpoint($sm) {
      position: absolute;
      top: 0;
      right: 50px;
    }
  }
  .teaser-detail .teaser-title {
    font-size: 20px;
  }
}

.pane-news-panel-pane-7 {
  padding: 0 10px 10px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  .pane-title {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.social-share a {
  border: none;
}

/**
 * Downloads
 *
 */

.downloads-menu ul {
  margin: 0;
  padding: 0;
}

.downloads-menu li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.downloads-menu a {
  display: block;
  position: relative;
  background-color: $wcm-white;
  color: $wcm-dark-orange;
  border-bottom: 1px solid $wcm-border-gray;
  font-size: 13px;
  line-height: 50px;
  text-align: right;
  padding: 0 60px 0 0;
  @include transition(background-color .25s);
  &:after {
    content: '\e802';
    position: absolute;
    right: 10px;
    top: 5px;
    width: 40px;
    height: 40px;
    color: $wcm-border-gray;
    border: 1px solid $wcm-border-gray;
    border-radius: 50%;
    font-size: 17px;
    text-align: center;
    @include fontello();
    line-height: 42px;
    @include transition(background-color .25s);
  }
  &:hover {
    color: $wcm-bright-orange;
    background-color: #f7f7f7;
    text-decoration: none;
    border-width: 0 0 1px;
  }
  &:hover:after {
    border: 1px solid $wcm-bright-orange;
    color: $wcm-bright-orange;
  }
}

/**
 * Service Index
 *
 */

.pane-services-panel-pane-1 {
  .item-list > h4 {
    margin: 10px 0;
    padding: 0 0 0 20px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
  }
  .item-list > ul li {
    list-style-type: none;
    margin-left: 1em;
  }
}

#main-content .category__title {
  margin: 10px 0;
}

#main-content .category__title a,
#main-content .audience-teaser__title a {
  color: $wcm-red;
}

.category__toggle {
  margin: 20px 0;
}

/**
 * Service Teasers
 *
 */

.pane-services-panel-pane-5 .pane-title,
.pane-services-panel-pane-6 .pane-title {
  margin-bottom: 15px;
}

.pane-services-panel-pane-3 .element__toggle {
  border-width: 0 0 1px;
  margin: 0 0 20px;
}

.pane-services-panel-pane-3 .element__toggle--open {
  margin: 0 0 10px;
}

/**
 * Systems Index
 *
 */

.pane-systems-panel-pane-1 {
  .view-content {
    margin-top: 30px;
  }
  h3 {
    color: #a5aeb3;
    margin-top: 15px;
    padding: 0 0 0 1em;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
  }
}

.system {
  @include clearfix();
}

.system__name {
  width: 42%;
  float: left;
  padding-left: 1em;
}

.system__link {
  width: 8%;
  float: left;
}

.system__related-service {
  width: 40%;
  float: right;
}

/* =Footer
----------------------------------------------------------*/

.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100%/10);
    float: left;
    padding: 0 10px 5px;
  }
}

.footer-nav li {
  line-height: 1.4;
  padding-bottom: 6px;
}

.footer-nav .menu-mlid-1140 > ul {
  @include breakpoint($md) {
    column-count: 2;
    column-gap: 20px;
  }
}

.footer-nav .menu-mlid-1140 {
  @include breakpoint($md) {
    width: 34%;
  }
}

.footer-nav .menu-mlid-1141 {
  @include breakpoint($md) {
    width: 14%;
  }
}

/**
 * Tables
 *
 */

.main-content .fieldable-panels-pane table {
  width: 100%;
  p {
    margin: 0;
  }
}

.view-weight table {
  width: 100%;
}

.page-node-1578 table,
.page-node-1577 table {
  font-size: 12px;
}

.pane-bundle-callout {
  margin-top: 2rem;
}

/**
 * Misc
 *
 */

.site-main .callout__link:after {
  content: '';
  padding: 0;
  display: none;
}

.faq-read-more {
  margin: 20px 0;
}


/**
 * Reveal restyling
 *
 */
.ctools-collapsible-container {
   .ctools-toggle {
       display: none;
   }
}
.ctools-collapsible-processed {
   .ctools-collapsible-handle h2 {
       position: relative;
       font-size: 18px;
       line-height: 20px;
       color: $wcm-dark-orange;
       border: 1px solid $wcm-border-gray;
       border-width: 3px 0 0;
       cursor: pointer;
       margin: 0;
       padding: 15px 55px 15px 10px;
       font-weight: 600;

       &:hover {
           color: $wcm-bright-orange;
       }

       &:after {
           content: '-';
           width: 50px;
           display: block;
           position: absolute;
           top: 15px;
           right: 0;
           font-size: 30px;
           font-weight: 600;
           text-align: center;
           border-left: 3px solid $wcm-border-gray;
       }
   }
   
   &.ctools-collapsed .ctools-collapsible-handle h2:after {
       content: '+';
   }
}