.view-alerts{
    .views-exposed-form{
        padding-left: 0;
        padding-right: 0;

        .views-exposed-widget{
            width: 99%;

            @include breakpoint($md) {
                width: 40%;
            }
        }
        
    }

    .view-content{
        .views-label{
            font-weight: bold;
        }

        .views-field-field-alert-type{
            a{
                color: $wcm-white;
                background-color: $wcm-dark-orange;
                display: inline-block;
                padding: .1rem 1.25rem .2rem 1.25rem;
                border-radius: 1.5rem;
                margin-top: 1rem;
                font-size: $font-size-base - 2px;
            }
        }
    }
}

.page-node.node-type-alert{

    .pane-bundle-quick-links{
        margin-bottom: 3rem;
        ul{
            list-style: none;
            
            li{
                margin-left: 0;
            }
        }
    }

    .field-label{
        display: inline;
        font-weight: bold;
    }

    .pane-node-field-alert-type{
        background-color: $wcm-bg-gray;
        padding: 2rem 2rem 0rem 2rem;

        .field-alert-type{
            color: $wcm-white;
            background-color: $wcm-dark-orange;
            display: inline-block;
            padding: .1rem 1.25rem .2rem 1.25rem;
            border-radius: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .pane-node-field-related-services{
        background-color: $wcm-bg-gray;
        padding: 0rem 2rem 1rem 2rem;

        .pane-title{
            display: inline-block;
            font-weight: bold;
        }

        .related-services{
            list-style-type: none;
            display: inline-block;
            padding: 0;
            
            li{
                display: inline;

                &:not(:last-child)::after{
                    content: ", ";
                }
            }
        }
    }

    .pane-node-updated{
        
        .pane-title{
            font-weight: bold;
        }

        @include breakpoint($md){
            padding-left: 4rem;
        }
    }

    .pane-node-field-alert-inception{
        @include breakpoint($md){
            padding-left: 4rem;
        }
    }

    .pane-node-field-alert-expiration{
        @include breakpoint($md){
            padding-left: 4rem;
        }
    }
}