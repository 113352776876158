.node-type-newsletter{

    .pane-node-title{
        h1{
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .pane-node-field-featured-image{
        margin-bottom: 36px;
    }

    .pane-node-body{
        margin-bottom: 40px;
    }

    .pane-node-field-news-posts{
        border: 1px solid $wcm-border-gray;
        margin-bottom: 20px;

        .panel-pane{
            padding-left: 40px;
            padding-right: 40px;
        }

        .newsletter-header{
            &.current{
                background: $wcm-red;   
            }
            &.past{
                background: $wcm-dark-gray;
            }
            color: $wcm-white;
            padding: 12px 40px 10px 40px;
            margin-bottom: 28px;
        }

        .node-news-post{
            margin-bottom: 36px;
            
            .pane-node-created{
                color: $wcm-med-gray;
                text-transform: uppercase;
                font-family: $headings-font-family;
                font-size: 12px;
            }

            .pane-node-body{
                margin-bottom: initial;
            }

            .hero-image{
                text-align: right;
                > * {
                    max-width: 100%;
                }
            }
        }

        .pane-node-field-news-category{
            .links{
                li{
                    display: inline;
                    margin-right: 10px;
                    a{
                        color: $wcm-white;
                        background: $wcm-dark-orange;
                        padding: 3px 14px 4px 14px;
                        border-radius: 15px;
                        border-bottom: none;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .pane-newsletters-panel-pane-1{
        margin-bottom: 20px;
        padding: 20px 40px 20px 40px;
        background-color: $wcm-bg-gray;
        border: 1px solid $wcm-border-gray;

        .view-newsletters{
            margin-left: 1em;
            list-style: none;
            .views-row{
                display: list-item;
                margin-left: .5em;
                .field-content{
                    &:before{
                        content: "\2022 ";
                        font-weight: bold;
                        padding-right: .5em;
                    }
                }
            }
        }
    }

}

// Homepage Newsletter announcement
.pane-newsletters-panel-pane-2{
    text-align: center;

    .view{
        color: $wcm-white;
        background-color: $wcm-dark-orange;
        padding: 1rem 0;

        .view-header{
            font-weight: bold;
            font-size: $font-size-h4;
        }

        a{
            color: $wcm-white;
        }
    }
}