.page-node-3465{

    .pane-node-title{
        text-align: center;
        
        .title{
            color: $wcm-bright-orange;
        }
    }

    .panel-pane{
        margin-top: 3rem;
    }

    .panel-col-top{
        @include container-fixed();


    }

    .center-wrapper{
        @include container-wrap();
        background-color: $wcm-bg-gray;

        >.panel-panel{
            @include make-xs-column(12);
            @include make-md-column(6);
        }

        .pane-title{
            color: $wcm-red;
        }

    }

    .panel-col-middle{
        @include container-wrap();
    }

    .panel-col-bottom{
        @include container-wrap();

        .inside{

            @include make-row();

            .pane-bundle-image{
                @include make-xs-column(12);
                @include make-md-column(4);

                .pane-title{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    a{
                        color: $wcm-white;
                        &::after{
                            color: $wcm-white;
                        }
                    }
                }
            }

            .pane-bundle-callout{
                @include make-xs-column(12);
            }

            .pane-bundle-text{
                @include make-xs-column(12);

                .pane-title{
                    @include center-block();
                    text-align: center;
                }
            }

            .pane-wcmc-its-help-banner{
                @include make-xs-column(12);

                /**
                * Help Banner
                *
                */
                .its-service-help-banner {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;

                    @include clearfix();
                    @include breakpoint($sm) {
                        width: 60%;
                    }
                    @include breakpoint($md) {
                        width: 100%;
                    }
                    .pane-title {
                        margin: 0 0 15px;
                        @include breakpoint($md) {
                            float: left;
                            margin: 10px 60px 0 0;
                        }
                    }
                    a {
                        text-decoration: none;
                    }
                    .help {
                        margin-bottom: 0;
                        @include breakpoint($md) {
                            float: left;
                            margin-right: 40px;
                            padding-right: 40px;
                            border-right: 1px solid #efefef;
                        }
                        @include breakpoint($lg) {
                            margin-right: 80px;
                            padding-right: 80px;
                        }
                        .icon {
                            @include breakpoint($sm) {
                            margin: -13px 5px 0 0;
                            width: auto;
                            }
                        }
                    }
                
                    .help--smartdesk {
                        @include breakpoint($sm) {
                            margin-right: 0;
                            padding-right: 0;
                            border: none;
                        }
                    }
                
                    .help__info {
                        width: auto;
                        padding-top: 20px;
                        @include breakpoint($sm) {
                            padding-top: 5px;
                        }
                    }
                    .cta-icon {
                        fill: $wcm-bright-orange;
                        color: $wcm-dark-orange;
                    }
                }
            }
            
        }

    }

}