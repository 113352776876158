/**
 * Styles related to the Teams content type and index display
 *
 */

/**
 *  Index
 */

.team {
  border-bottom: 1px solid #efefef;
  padding: 0 0 1em;
  margin-bottom: 20px;
  @include clearfix();
}

.team__icon {
  display: none;
  @include breakpoint($sm) {
    float: left;
    @include fontello-its();
    font-size: 34px;
    width: 7%;
    &:before {
      color: $wcm-bright-orange;
    }
  }
}

.team-349 .team__icon {
  &:before {
    content: '\e81e';
  }
}

.team-330 .team__icon {
  &:before {
    content: '\e831';
  }
}

.team-331 .team__icon {
  &:before {
    content: '\e822';
  }
}

.team-332 .team__icon {
  &:before {
    content: '\e823';
  }
}

.team-333 .team__icon {
  &:before {
    content: '\e824';
  }
}

.team-334 .team__icon {
  &:before {
    content: '\e800';
  }
}

.team-335 .team__icon {
  &:before {
    content: '\e82f';
  }
}

.team-337 .team__icon {
  &:before {
    content: '\e826';
  }
}

.team-338 .team__icon {
  &:before {
    content: '\e827';
  }
}

.team-339 .team__icon {
  &:before {
    content: '\e830';
  }
}

.team-340 .team__icon {
  &:before {
    content: '\e82e';
  }
}

.team-341 .team__icon {
  &:before {
    content: '\e82d';
  }
}

.team-342 .team__icon {
  &:before {
    content: '\e820';
  }
}

.team-343 .team__icon {
  &:before {
    content: '\e825';
  }
}

.team-344 .team__icon {
  &:before {
    content: '\e833';
  }
}

.team-345 .team__icon {
  &:before {
    content: '\e82c';
  }
}

.team-348 .team__icon {
  &:before {
    content: '\e82a';
  }
}

.team-323 .team__icon {
  &:before {
    content: '\e834';
  }
}

.team-3194 .team__icon {
  &:before {
    font-family: 'Glyphicons Halflings';
    content: '\e125';
  }
}

.team-4596 .team__icon:before {
  content: '\e823';
}

.team__name a {
  font-family: $wcm-bold;
  color: $wcm-red;
  line-height: 1.3;
  font-size: 20px;
  margin-bottom: 5px;
  &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.team__teaser {
  @include breakpoint($sm) {
    float: left;
    width: 93%;
    padding-left: 30px;
  }
}

/**
 *  Individual Teams
 */

.pane-bundle-headshot h3 {
  margin-bottom: 20px;
}

.headshot-entry {
  width: 120px;
  min-height: 280px;
  margin: 0 auto;
  @include breakpoint($sm) {
    margin-right: 30px;
  }
}

.headshot {
  width: 120px;
  height: 160px;
}

.headshot-details {
  width: 120px;
}