/**
 * Tabs
 *
 * These are here mostly to override system tab styles.
 */

#main-content {
  .ui-tabs .ui-tabs-panel {
    padding: 10px 0;
  }
  .ui-widget {
    font-size: 14px;
  }
  .ui-widget-header {
    background: none;
    font-size: 14px;
    @include breakpoint($sm) {
      font-size: 16px;
    }
  }
  .ui-widget-content {
    background: none;
    a {
      color: $wcm-dark-orange;
    }
  }
  .ui-tabs .ui-tabs-nav {
    border-bottom: 1px solid #cfd1cb !important;
  }
  .ui-tabs .ui-tabs-nav li {
    width: 32%;
  }
  .ui-tabs .ui-tabs-nav li.ui-state-default {
    background: none;
  }
  .ui-tabs .ui-tabs-nav li.ui-state-active,
  .ui-tabs .ui-tabs-nav li.ui-state-hover,
  .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
    background: #f3fafc;
    background-image: none;
  }

  .ui-tabs .ui-tabs-nav li.ui-state-active,
  .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
    border: 1px solid #cfd1cb !important;
    border-width: 1px 1px 0 !important;
  }

  .ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    -moz-border-radius-topright: 0px;
    -webkit-border-top-right-radius: 0px;
    -khtml-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    -khtml-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -khtml-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

 .ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    -moz-border-radius-bottomright: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -khtml-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .ui-tabs .ui-tabs-nav li a {
    width: 100%;
    text-align: center;
  }
  .item-list .ui-tabs-nav li a:after {
    content: '';
  }
}