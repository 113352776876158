/**
 * Styles related to form elements
 *
 */

.webform-component-textarea .grippie {
  display: none;
}

.howto-nav{
  .views-exposed-form{
    background: #f7f7f7;
  }
}

.views-exposed-form {
  width: 100%;
  padding: 2rem;

  .views-widget-filter-and-or {
    width: auto;
    display: none;
    @include breakpoint($md) {
      display: block;
      margin-top: 50px;
    }
  }
}

.pane-views-exp-guides-find-guide-switcher {
  .views-exposed-form .views-exposed-widget {
    width: 100%;
  }
  #edit-by-cat-wrapper {
    label {
      @include breakpoint($md) {
        width: 50%;
        float: left;
      }
    }
    .views-widget {
      @include breakpoint($md) {
        width: 50%;
        float: left;
      }
    }
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 32px;
}

.views-exposed-form .views-exposed-widget {
  .select2 {
    min-width: 300px;
  }
}

/* =Traning and Guides Exposed Form
----------------------------------------------------------*/

.pane-views-exp-training-panel-pane-2 {
  .views-exposed-form {
    margin-bottom: 30px;
    .views-exposed-widget .select2 {
      @include breakpoint($md) {
        min-width: 200px;
      }
      @include breakpoint($lg) {
        min-width: 260px;
      }
    }
  }
}

/* =Headshot Edit Form
----------------------------------------------------------*/

#field-headshot-values .field-name-field-headshot-description-long {
  display: none;
}


/* =News Exposed Filters Form
----------------------------------------------------------*/
.page-news{
  h2.teaser-title a{
    &:hover{
      border-bottom: 1px solid !important;
    }
  }

  //New page View
  .pane-news-panel-pane-1{
    .view-id-news{
      
      .view-filters{
        .views-exposed-form{
          background-color: $wcm-white;
          padding: 20px 0px 20px 0px;
          label{
            //margin-bottom: 10px;
            line-height: $line-height-base;
          }
          .views-exposed-widget{
            display: inline-block;
            vertical-align: bottom;
            padding-right: 3em;
            float: none;

            .form-item{
              width: 100%;
            }

            label{
              display: block;
            }
          }
        }
      }

    }
  }
}