@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Typography
 *
 */
h3.pane-title {
  color: #555555;
  margin-bottom: 12.5px;
}

/**
 * Tabs
 *
 * These are here mostly to override system tab styles.
 */
#main-content .ui-tabs .ui-tabs-panel {
  padding: 10px 0;
}

#main-content .ui-widget {
  font-size: 14px;
}

#main-content .ui-widget-header {
  background: none;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  #main-content .ui-widget-header {
    font-size: 16px;
  }
}

#main-content .ui-widget-content {
  background: none;
}

#main-content .ui-widget-content a {
  color: #cf4520;
}

#main-content .ui-tabs .ui-tabs-nav {
  border-bottom: 1px solid #cfd1cb !important;
}

#main-content .ui-tabs .ui-tabs-nav li {
  width: 32%;
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-default {
  background: none;
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-active,
#main-content .ui-tabs .ui-tabs-nav li.ui-state-hover,
#main-content .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
  background: #f3fafc;
  background-image: none;
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-active,
#main-content .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
  border: 1px solid #cfd1cb !important;
  border-width: 1px 1px 0 !important;
}

#main-content .ui-corner-all, #main-content .ui-corner-top, #main-content .ui-corner-right, #main-content .ui-corner-tr {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-top, #main-content .ui-corner-left, #main-content .ui-corner-tl {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  -khtml-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-bottom, #main-content .ui-corner-left, #main-content .ui-corner-bl {
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -khtml-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-bottom, #main-content .ui-corner-right, #main-content .ui-corner-br {
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#main-content .ui-tabs .ui-tabs-nav li a {
  width: 100%;
  text-align: center;
}

#main-content .item-list .ui-tabs-nav li a:after {
  content: '';
}

/**
 * Styles related to form elements
 *
 */
.webform-component-textarea .grippie {
  display: none;
}

.howto-nav .views-exposed-form {
  background: #f7f7f7;
}

.views-exposed-form {
  width: 100%;
  padding: 2rem;
}

.views-exposed-form .views-widget-filter-and-or {
  width: auto;
  display: none;
}

@media screen and (min-width: 992px) {
  .views-exposed-form .views-widget-filter-and-or {
    display: block;
    margin-top: 50px;
  }
}

.pane-views-exp-guides-find-guide-switcher .views-exposed-form .views-exposed-widget {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .pane-views-exp-guides-find-guide-switcher #edit-by-cat-wrapper label {
    width: 50%;
    float: left;
  }
}

@media screen and (min-width: 992px) {
  .pane-views-exp-guides-find-guide-switcher #edit-by-cat-wrapper .views-widget {
    width: 50%;
    float: left;
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 32px;
}

.views-exposed-form .views-exposed-widget .select2 {
  min-width: 300px;
}

/* =Traning and Guides Exposed Form
----------------------------------------------------------*/
.pane-views-exp-training-panel-pane-2 .views-exposed-form {
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .pane-views-exp-training-panel-pane-2 .views-exposed-form .views-exposed-widget .select2 {
    min-width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .pane-views-exp-training-panel-pane-2 .views-exposed-form .views-exposed-widget .select2 {
    min-width: 260px;
  }
}

/* =Headshot Edit Form
----------------------------------------------------------*/
#field-headshot-values .field-name-field-headshot-description-long {
  display: none;
}

/* =News Exposed Filters Form
----------------------------------------------------------*/
.page-news h2.teaser-title a:hover {
  border-bottom: 1px solid !important;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-filters .views-exposed-form {
  background-color: #fff;
  padding: 20px 0px 20px 0px;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-filters .views-exposed-form label {
  line-height: 1.66667;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-filters .views-exposed-form .views-exposed-widget {
  display: inline-block;
  vertical-align: bottom;
  padding-right: 3em;
  float: none;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-filters .views-exposed-form .views-exposed-widget .form-item {
  width: 100%;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-filters .views-exposed-form .views-exposed-widget label {
  display: block;
}

/**
 * Branding
 *
 */
.brand__lockup img {
  width: auto;
  height: 74px;
  margin: 20px 0 10px;
}

/**
 * Primary Navigation
 *
 */
@media screen and (min-width: 768px) {
  #primary-nav {
    line-height: 18px;
  }
}

@media screen and (min-width: 768px) {
  #primary-nav .level-1 {
    width: 11%;
  }
}

@media screen and (min-width: 768px) {
  #primary-nav .menu-mlid-1141 {
    width: 21%;
  }
}

/**
 * Drawer Nav
 *
 */
#drawer-nav .active-trail > a {
  color: #e87722;
}

@media screen and (min-width: 768px) {
  #drawer-nav .menu-mlid-1140 .level-2 {
    width: 25%;
  }
}

.menu-mlid-1140 .level-3,
.menu-mlid-1590 .level-3 {
  display: none;
}

/**
 * General Layout
 *
 */
.main-content {
  margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .main-content {
    margin-bottom: 0;
  }
}

/**
 * Service Nodes
 *
 */
.pane-node-field-service-synonyms .field-label {
  display: inline;
  font-weight: 700;
}

.node-type-service #main-content .panel-pane {
  border-bottom: 1px solid #efefef;
  padding-bottom: 5px;
  margin-top: 2rem;
}

.node-type-service .information-sidebar .panel-pane {
  margin-top: 1rem;
}

/**
 * Get Help
 *
 */
.get-help {
  border-bottom: 1px solid #efefef;
  padding: 0 0 30px;
  margin-bottom: 30px;
}

.get-help:before, .get-help:after {
  content: " ";
  display: table;
}

.get-help:after {
  clear: both;
}

.get-help > *:last-child,
.get-help > *:last-child > *:last-child,
.get-help > *:last-child > *:last-child > *:last-child {
  margin: 0;
}

.walkup-directions:before, .walkup-directions:after {
  content: " ";
  display: table;
}

.walkup-directions:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .walkup-map {
    width: 300px;
    float: left;
  }
}

@media screen and (min-width: 768px) {
  .walkup-address {
    width: 310px;
    float: left;
    padding-left: 30px;
  }
}

.consult-description:before, .consult-description:after {
  content: " ";
  display: table;
}

.consult-description:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .consult-description > p {
    width: 300px;
    float: left;
  }
}

@media screen and (min-width: 768px) {
  .consult-services {
    width: 310px;
    float: left;
    padding-left: 30px;
  }
}

/**
 * Synonyms
 *
 */
.pane-node-field-service-synonyms {
  color: #666666;
  margin-bottom: 30px;
  font-size: 14px;
}

.pane-node-field-service-synonyms .pane-title {
  display: none;
}

.field-name-field-service-synonyms .field-label {
  padding-right: 5px;
}

.synonyms ul {
  display: inline;
  margin: 0;
  padding: 0;
}

.synonyms li {
  display: inline;
  margin: 0;
  padding: 0 10px 0 0;
}

/**
 * Status Notification
 *
 */
.service-status {
  text-align: center;
  border: 1px solid #ccc;
  padding: 3px 0;
  border-radius: 20px;
}

.service-status:after {
  content: '\25CF';
  font-size: 18px;
  padding-left: 5px;
}

.status-alerts {
  font-size: 12px;
}

.service-status-0::after,
.service-status-Good::after {
  color: #62b53c;
}

.service-status-1::after,
.service-status-Maintenance::after {
  color: #c8d6a5;
}

.service-status-2::after,
.service-status-Incident::after {
  color: #ff9903;
}

.service-status-3:after {
  color: #ffe703;
}

.service-status-4:after {
  color: #fc412f;
}

.js .pane-alerts-panel-pane-1 .view-content {
  display: none;
}

.alert__total {
  color: #3787b0;
  cursor: pointer;
  padding-bottom: 5px;
}

.alert__total:after {
  padding-left: 5px;
  content: '\e814';
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

.alert__total.open:after {
  content: '\e82b';
}

.service-status-list {
  border-bottom: 1px solid #efefef;
  padding: 0 0 0.75em;
  margin-bottom: 10px;
}

.service-status-list:before, .service-status-list:after {
  content: " ";
  display: table;
}

.service-status-list:after {
  clear: both;
}

.service-status-list .service-status {
  width: 50%;
  float: right;
  line-height: 1.5;
  padding: 0;
  font-size: 13px;
}

.service-status-list .service-status a {
  border: unset;
  color: unset;
}

.service-status-list .service-status a:hover {
  color: #b31b1b;
}

.service-status-list__title {
  width: 50%;
  float: left;
  line-height: 1.5;
  font-size: 13px;
}

/**
 *  Alert Index
 *
 */
.alerts {
  padding: 30px 0;
  border-bottom: 1px solid #efefef;
}

.alert__title {
  margin-bottom: 5px;
}

.alert__title a {
  color: #b31b1b;
}

@media screen and (min-width: 768px) {
  .pane-alerts-panel-pane-2 {
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .pane-alerts-panel-pane-2 .view-footer {
    position: absolute;
    top: 12px;
    right: 50px;
  }
}

.item-list .pagination {
  margin: 30px 0;
}

/**
 * Sidebar Panes
 *
 */
.information-sidebar .pane-title {
  font-size: 18px;
  color: #555555;
}

/**
 * Service Help Pane
 *
 */
.help {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 30px;
}

.help:before, .help:after {
  content: " ";
  display: table;
}

.help:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .help {
    width: auto;
    margin: auto;
  }
}

.help .icon {
  float: left;
  width: 25%;
  color: #e7751d;
  font-size: 46px;
}

.help .icon:before {
  margin-left: 0;
}

.help .cta-icon {
  fill: #e7751d;
  color: #cf4520;
  margin-right: 10px;
}

.help__info {
  float: left;
  width: 75%;
  padding: 5px 0 0;
  line-height: 1.7;
}

.help__title {
  font-size: 18px;
}

.help__action {
  color: #666666;
}

.help--email {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .help--email .help__info {
    position: relative;
    top: 25px;
  }
}

/**
 * Acronym Finder
 * Turning this off for now.
 */
.lookup-widget__help {
  color: #666666;
}

.lookup-widget input {
  position: relative;
  width: 100%;
  padding: 5px;
  font-size: 13px;
}

.lookup-description {
  display: none;
}

.lookup-widget__result {
  display: none;
  position: absolute;
  z-index: 1000;
  background: #eee;
  border: 1px solid #ccc;
  padding: 1em;
  font-size: 13px;
}

.ui-autocomplete {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.ui-autocomplete .ui-menu-item > a.ui-corner-all {
  display: block;
  padding: 8px 15px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  border-top: 1px solid #ccc;
  font-size: 12px;
}

.ui-autocomplete .ui-menu-item > a.ui-corner-all:first-child {
  border: none;
}

.ui-autocomplete .ui-menu-item > a.ui-corner-all.ui-state-hover, .ui-autocomplete .ui-menu-item > a.ui-corner-all.ui-state-active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  border-radius: 0px;
  background-image: none;
}

/**
 *  ITS Glossary
 *
 */
.pane-acronyms-panel-pane-2 {
  color: #666666;
}

.pane-acronyms-panel-pane-2 .views-exposed-form {
  background: none;
  padding: 10px 0;
}

.pane-acronyms-panel-pane-2 .views-exposed-form .views-exposed-widget select {
  color: #666666;
}

.pane-acronyms-panel-pane-2 .views-exposed-form .views-exposed-widget input {
  position: relative;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  color: #666666;
  font-size: 13px;
}

.pane-acronyms-panel-pane-2 .views-exposed-form .views-exposed-widget .form-submit {
  width: 100px;
  margin: 5px 0 0 0;
  background: #cf4520;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  border: none;
  font-size: 12px;
}

.pane-acronyms-panel-pane-2 .views-exposed-form .views-exposed-widget .form-submit:hover {
  background: #e7751d;
}

.pane-acronyms-panel-pane-2 .view-acronyms .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 5px;
}

.glossary {
  margin-bottom: 5px;
}

.glossary__term {
  font-weight: bold;
}

/**
 * ITS Glossary Index
 *
 */
.view-acronyms table {
  margin: 20px 0 0;
}

.view-acronyms table tr.odd {
  background-color: #fff;
}

.view-acronyms table .views-field-title {
  font-weight: 700;
  padding: 10px;
  line-height: 1.4;
}

.view-acronyms table .views-field-field-acronym-definition {
  padding: 10px;
  line-height: 1.4;
}

.view-acronyms .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 35px;
}

.view-acronyms .views-exposed-form label[for="edit-acronym"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/**
 * Training & Guides
 *
 */
.guide {
  margin-bottom: 15px;
}

.guide--document:before {
  content: '\e81f';
  color: #333;
  margin-right: 5px;
  font-size: 24px;
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

.guide--video:before {
  content: '\e828';
}

.pane-training-panel-pane-2 {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #efefef;
}

.training__related-service,
.guide__related-service {
  padding-left: 10px;
}

.training__related-service a,
.guide__related-service a {
  color: #b1b9bd;
}

/**
 * News Teasers
 *
 */
.view-teaser {
  border-bottom: 1px solid #efefef;
}

.view-teaser:last-child {
  border: none;
}

.teaser-title {
  font-size: 20px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .teaser__image {
    float: right;
    height: auto;
    padding-left: 20px;
    margin-top: 20px;
  }
}

.teaser__image img {
  width: 150px;
  height: 150px;
}

.teaser__image a > img {
  border-radius: 0px;
}

.teaser__detail {
  padding: 20px 0;
}

.featured-news-teaser .teaser__detail {
  padding: 1rem;
}

.teaser__service {
  font-size: 13px;
  margin-bottom: 10px;
}

.page-news .panel-pane.pane-fieldable-panels-pane.pane-bundle-text div.fieldable-panels-pane div h1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-content .view-teaser .teaser-title {
  margin-bottom: .5em;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-content .view-teaser .post-date {
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: .8em;
  font-weight: lighter;
  text-transform: uppercase;
  margin-bottom: .5em;
}

.page-news .pane-news-panel-pane-1 .view-id-news .view-content .view-teaser .views-field-field-news-category a {
  color: #fff;
  background: #cf4520;
  padding: 3px 14px 4px 14px;
  border-radius: 15px;
  border-bottom: none;
  font-size: 14px;
}

.node-type-news-post .pane-node-field-news-category .field-news-category li {
  margin: auto 10px 10px auto;
  display: inline-block;
  background: #cf4520;
  padding: 0px 12px 2px 12px;
  border-radius: 15px;
}

.node-type-news-post .pane-node-field-news-category .field-news-category li a {
  color: #fff;
  border-bottom: none;
  font-size: 14px;
}

.node-type-news-post .pane-node-field-news-category .field-news-category li a:hover {
  border-bottom: 1px solid #fff;
}

.node-type-news-post .pane-node-field-news-posts {
  border: 1px solid #dddddd;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.node-type-news-post .pane-node-field-news-posts div {
  margin-left: 1em;
}

.node-type-news-post .pane-node-field-news-posts div a {
  display: table;
}

.node-type-news-post .pane-node-field-news-posts div a:before {
  content: "\2022  ";
  color: #555555;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 {
    position: relative;
    margin: 30px 0;
  }
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .view-content {
    border: 1px solid #efefef;
    padding: 15px;
    margin: 20px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .view-footer {
    position: absolute;
    top: 0;
    right: 50px;
  }
}

.pane-news-panel-pane-5 .teaser-detail .teaser-title {
  font-size: 20px;
}

.pane-news-panel-pane-7 {
  padding: 0 10px 10px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
}

.pane-news-panel-pane-7 .pane-title {
  font-size: 14px;
  margin-bottom: 0;
}

.social-share a {
  border: none;
}

/**
 * Downloads
 *
 */
.downloads-menu ul {
  margin: 0;
  padding: 0;
}

.downloads-menu li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.downloads-menu a {
  display: block;
  position: relative;
  background-color: #fff;
  color: #cf4520;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  line-height: 50px;
  text-align: right;
  padding: 0 60px 0 0;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.downloads-menu a:after {
  content: '\e802';
  position: absolute;
  right: 10px;
  top: 5px;
  width: 40px;
  height: 40px;
  color: #dddddd;
  border: 1px solid #dddddd;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  line-height: 42px;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.downloads-menu a:hover {
  color: #e7751d;
  background-color: #f7f7f7;
  text-decoration: none;
  border-width: 0 0 1px;
}

.downloads-menu a:hover:after {
  border: 1px solid #e7751d;
  color: #e7751d;
}

/**
 * Service Index
 *
 */
.pane-services-panel-pane-1 .item-list > h4 {
  margin: 10px 0;
  padding: 0 0 0 20px;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
}

.pane-services-panel-pane-1 .item-list > ul li {
  list-style-type: none;
  margin-left: 1em;
}

#main-content .category__title {
  margin: 10px 0;
}

#main-content .category__title a,
#main-content .audience-teaser__title a {
  color: #b31b1b;
}

.category__toggle {
  margin: 20px 0;
}

/**
 * Service Teasers
 *
 */
.pane-services-panel-pane-5 .pane-title,
.pane-services-panel-pane-6 .pane-title {
  margin-bottom: 15px;
}

.pane-services-panel-pane-3 .element__toggle {
  border-width: 0 0 1px;
  margin: 0 0 20px;
}

.pane-services-panel-pane-3 .element__toggle--open {
  margin: 0 0 10px;
}

/**
 * Systems Index
 *
 */
.pane-systems-panel-pane-1 .view-content {
  margin-top: 30px;
}

.pane-systems-panel-pane-1 h3 {
  color: #a5aeb3;
  margin-top: 15px;
  padding: 0 0 0 1em;
  border-bottom: 1px solid #efefef;
  font-size: 14px;
}

.system:before, .system:after {
  content: " ";
  display: table;
}

.system:after {
  clear: both;
}

.system__name {
  width: 42%;
  float: left;
  padding-left: 1em;
}

.system__link {
  width: 8%;
  float: left;
}

.system__related-service {
  width: 40%;
  float: right;
}

/* =Footer
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px;
  }
}

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 10%;
    float: left;
    padding: 0 10px 5px;
  }
}

.footer-nav li {
  line-height: 1.4;
  padding-bottom: 6px;
}

@media screen and (min-width: 992px) {
  .footer-nav .menu-mlid-1140 > ul {
    column-count: 2;
    column-gap: 20px;
  }
}

@media screen and (min-width: 992px) {
  .footer-nav .menu-mlid-1140 {
    width: 34%;
  }
}

@media screen and (min-width: 992px) {
  .footer-nav .menu-mlid-1141 {
    width: 14%;
  }
}

/**
 * Tables
 *
 */
.main-content .fieldable-panels-pane table {
  width: 100%;
}

.main-content .fieldable-panels-pane table p {
  margin: 0;
}

.view-weight table {
  width: 100%;
}

.page-node-1578 table,
.page-node-1577 table {
  font-size: 12px;
}

.pane-bundle-callout {
  margin-top: 2rem;
}

/**
 * Misc
 *
 */
.site-main .callout__link:after {
  content: '';
  padding: 0;
  display: none;
}

.faq-read-more {
  margin: 20px 0;
}

/**
 * Reveal restyling
 *
 */
.ctools-collapsible-container .ctools-toggle {
  display: none;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #cf4520;
  border: 1px solid #dddddd;
  border-width: 3px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:hover {
  color: #e7751d;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #dddddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+';
}

/**
 * Styles related to the Teams content type and index display
 *
 */
/**
 *  Index
 */
.team {
  border-bottom: 1px solid #efefef;
  padding: 0 0 1em;
  margin-bottom: 20px;
}

.team:before, .team:after {
  content: " ";
  display: table;
}

.team:after {
  clear: both;
}

.team__icon {
  display: none;
}

@media screen and (min-width: 768px) {
  .team__icon {
    float: left;
    font-family: "fontello-its";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    font-size: 34px;
    width: 7%;
  }
  .team__icon:before {
    color: #e7751d;
  }
}

.team-349 .team__icon:before {
  content: '\e81e';
}

.team-330 .team__icon:before {
  content: '\e831';
}

.team-331 .team__icon:before {
  content: '\e822';
}

.team-332 .team__icon:before {
  content: '\e823';
}

.team-333 .team__icon:before {
  content: '\e824';
}

.team-334 .team__icon:before {
  content: '\e800';
}

.team-335 .team__icon:before {
  content: '\e82f';
}

.team-337 .team__icon:before {
  content: '\e826';
}

.team-338 .team__icon:before {
  content: '\e827';
}

.team-339 .team__icon:before {
  content: '\e830';
}

.team-340 .team__icon:before {
  content: '\e82e';
}

.team-341 .team__icon:before {
  content: '\e82d';
}

.team-342 .team__icon:before {
  content: '\e820';
}

.team-343 .team__icon:before {
  content: '\e825';
}

.team-344 .team__icon:before {
  content: '\e833';
}

.team-345 .team__icon:before {
  content: '\e82c';
}

.team-348 .team__icon:before {
  content: '\e82a';
}

.team-323 .team__icon:before {
  content: '\e834';
}

.team-3194 .team__icon:before {
  font-family: 'Glyphicons Halflings';
  content: '\e125';
}

.team-4596 .team__icon:before {
  content: '\e823';
}

.team__name a {
  font-family: "1898Sans-Bold", sans-serif;
  color: #b31b1b;
  line-height: 1.3;
  font-size: 20px;
  margin-bottom: 5px;
}

.team__name a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .team__teaser {
    float: left;
    width: 93%;
    padding-left: 30px;
  }
}

/**
 *  Individual Teams
 */
.pane-bundle-headshot h3 {
  margin-bottom: 20px;
}

.headshot-entry {
  width: 120px;
  min-height: 280px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .headshot-entry {
    margin-right: 30px;
  }
}

.headshot {
  width: 120px;
  height: 160px;
}

.headshot-details {
  width: 120px;
}

/**
 * Audience Panes
 *
 */
.audience a {
  display: block;
  padding: 1em;
  border: 1px solid #ddd;
  text-decoration: none;
  border-radius: 20px;
  margin-bottom: 5px;
  padding: 10px 20px;
}

.audience a:after {
  float: right;
  margin: 0 5px 0 0;
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  font-size: 24px;
}

/**
 * Audience Banner
 *
 */
.audience-banner {
  width: 50%;
  white-space: nowrap;
}

.audience-banner > * {
  white-space: unset;
}

@media screen and (min-width: 992px) {
  .audience-banner {
    width: 33%;
  }
}

.audience-banner a {
  text-decoration: none;
  border: none;
}

.audience-banner a:before {
  padding: 0 0 5px;
  color: #e7751d;
  font-size: 46px;
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  width: 100%;
}

.audience-banner__title {
  text-align: center;
}

/**
 * Audience Teasers
 *
 */
.audience-teaser {
  border-bottom: 1px solid #efefef;
  margin-bottom: 15px;
  padding-top: 20px;
}

.audience-teaser:first-child {
  border-top: 1px solid #efefef;
  padding: 30px 0 20px;
  margin-top: 25px;
}

.audience-icon {
  color: #e7751d;
  font-size: 36px;
  font-family: "fontello-its";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

@media screen and (min-width: 768px) {
  .audience-icon {
    float: left;
    margin: -5px 15px 0 0;
  }
}

.audience-teaser__title {
  font-size: 20px;
  margin: 0 0 15px;
}

.audience-teaser__description h3 {
  color: #555555;
  font-size: 18px;
}

/**
 * Audience Teasers - Service Index
 *
 */
.ui-tabs .audience-teaser:first-child {
  border-top: none;
  padding-top: 0;
}

/**
 * Audience Services
 *
 */
.pane-services-panel-pane-4 .pane-title {
  border-top: 1px solid #efefef;
  font-size: 20px;
  padding-top: 30px;
  color: #b31b1b;
}

.pane-services-panel-pane-4 h3 {
  margin-top: 20px;
  color: #555555;
  font-size: 18px;
}

.pane-audience-panel-pane-3 {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.pane-audience-panel-pane-3 h3.pane-title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .pane-audience-panel-pane-3 .view-content .audience-cta {
    display: inline-block;
    width: 49%;
  }
}

@media screen and (min-width: 992px) {
  .pane-audience-panel-pane-3 .view-content .audience-cta {
    width: 32.5%;
  }
}

.pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__tile {
  z-index: initial;
  float: none;
  width: auto;
  height: auto;
  border: none;
  padding: 3rem 1rem;
  margin: 5rem auto;
  width: 80%;
}

@media screen and (min-width: 992px) {
  .pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__tile {
    margin: 2.5rem auto;
  }
}

.pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__tile::after {
  opacity: 1;
}

.pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__tile:hover {
  z-index: 100;
  border: none;
}

.pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__detail {
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
  width: auto;
  font-size: unset;
}

.pane-audience-panel-pane-3 .view-content .audience-cta .wcm-cta__detail .cta-icon {
  width: 35px;
  height: auto;
  max-height: 35px;
  margin-bottom: 1rem;
}

/**
 * Audience Nav
 *
 */
.audience-nav {
  margin-bottom: 30px;
}

.audience-nav:before, .audience-nav:after {
  content: " ";
  display: table;
}

.audience-nav:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .audience-nav__icon {
    float: left;
    width: 20%;
  }
}

.audience-nav__icon a:hover {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .audience-nav__title {
    float: right;
    width: 80%;
  }
}

/**
 * Audience Icons
 *
 */
.audience-nav__icon a:before {
  color: #e7751d;
  display: none;
}

@media screen and (min-width: 768px) {
  .audience-nav__icon a:before {
    display: block;
    float: left;
    font-family: "fontello-its";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    font-size: 30px;
  }
}

.audience-banner--5 a:before,
.audience-5-icon:before,
.audience-nav--5 .audience-nav__icon a:before {
  content: '\e831';
}

.audience-banner--1 a:before,
.audience-1-icon:before,
.audience-nav--1 .audience-nav__icon a:before {
  content: '\e807';
}

.audience-banner--4 a:before,
.audience-4-icon:before,
.audience-nav--4 .audience-nav__icon a:before {
  content: '\e80b';
}

.audience-banner--157 a:before,
.audience-157-icon:before,
.audience-nav--157 .audience-nav__icon a:before {
  content: '\e80a';
}

.audience-banner--2 a:before,
.audience-2-icon:before,
.audience-nav--2 .audience-nav__icon a:before {
  content: '\e821';
}

.audience-banner--3 a:before,
.audience-3-icon:before,
.audience-nav--3 .audience-nav__icon a:before {
  content: '\e800';
}

.audience-banner--6 a:before,
.audience-6-icon:before,
.audience-nav--6 .audience-nav__icon a:before {
  content: '\e805';
}

/**
 * Services
 *
 */
/*MED GRAPHICS DESIGN*/
.portfolio {
  float: left;
  padding: 40px 30px 40px 0px;
}

.portfolio-headers {
  padding: 40px 0px 10px 0px;
}

.page-node-290 .node-type-service #main-content .panel-pane {
  border-bottom: 0px !important;
}

.field-basic-table-table > div {
  overflow-x: auto;
}

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

.page-node-3465 .pane-node-title {
  text-align: center;
}

.page-node-3465 .pane-node-title .title {
  color: #e7751d;
}

.page-node-3465 .panel-pane {
  margin-top: 3rem;
}

.page-node-3465 .panel-col-top {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.page-node-3465 .panel-col-top:before, .page-node-3465 .panel-col-top:after {
  content: " ";
  display: table;
}

.page-node-3465 .panel-col-top:after {
  clear: both;
}

.page-node-3465 .center-wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f7f7f7;
}

.page-node-3465 .center-wrapper:before, .page-node-3465 .center-wrapper:after {
  content: " ";
  display: table;
}

.page-node-3465 .center-wrapper:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .page-node-3465 .center-wrapper {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .page-node-3465 .center-wrapper {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .page-node-3465 .center-wrapper {
    width: 1170px;
  }
}

.page-node-3465 .center-wrapper > .panel-panel {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .page-node-3465 .center-wrapper > .panel-panel {
    float: left;
    width: 50%;
  }
}

.page-node-3465 .center-wrapper .pane-title {
  color: #b31b1b;
}

.page-node-3465 .panel-col-middle {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.page-node-3465 .panel-col-middle:before, .page-node-3465 .panel-col-middle:after {
  content: " ";
  display: table;
}

.page-node-3465 .panel-col-middle:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-middle {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .page-node-3465 .panel-col-middle {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .page-node-3465 .panel-col-middle {
    width: 1170px;
  }
}

.page-node-3465 .panel-col-bottom {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.page-node-3465 .panel-col-bottom:before, .page-node-3465 .panel-col-bottom:after {
  content: " ";
  display: table;
}

.page-node-3465 .panel-col-bottom:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-bottom {
    width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .page-node-3465 .panel-col-bottom {
    width: 970px;
  }
}

@media screen and (min-width: 1200px) {
  .page-node-3465 .panel-col-bottom {
    width: 1170px;
  }
}

.page-node-3465 .panel-col-bottom .inside {
  margin-left: -20px;
  margin-right: -20px;
}

.page-node-3465 .panel-col-bottom .inside:before, .page-node-3465 .panel-col-bottom .inside:after {
  content: " ";
  display: table;
}

.page-node-3465 .panel-col-bottom .inside:after {
  clear: both;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-image {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .page-node-3465 .panel-col-bottom .inside .pane-bundle-image {
    float: left;
    width: 33.33333%;
  }
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-image .pane-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-image .pane-title a {
  color: #fff;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-image .pane-title a::after {
  color: #fff;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-callout {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-text {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-node-3465 .panel-col-bottom .inside .pane-bundle-text .pane-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  /**
                * Help Banner
                *
                */
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner:before, .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner:after {
  content: " ";
  display: table;
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner {
    width: 60%;
  }
}

@media screen and (min-width: 992px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner {
    width: 100%;
  }
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .pane-title {
  margin: 0 0 15px;
}

@media screen and (min-width: 992px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .pane-title {
    float: left;
    margin: 10px 60px 0 0;
  }
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner a {
  text-decoration: none;
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help {
    float: left;
    margin-right: 40px;
    padding-right: 40px;
    border-right: 1px solid #efefef;
  }
}

@media screen and (min-width: 1200px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help {
    margin-right: 80px;
    padding-right: 80px;
  }
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help .icon {
    margin: -13px 5px 0 0;
    width: auto;
  }
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help--smartdesk {
    margin-right: 0;
    padding-right: 0;
    border: none;
  }
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help__info {
  width: auto;
  padding-top: 20px;
}

@media screen and (min-width: 768px) {
  .page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .help__info {
    padding-top: 5px;
  }
}

.page-node-3465 .panel-col-bottom .inside .pane-wcmc-its-help-banner .its-service-help-banner .cta-icon {
  fill: #e7751d;
  color: #cf4520;
}

.node-type-newsletter .pane-node-title h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.node-type-newsletter .pane-node-field-featured-image {
  margin-bottom: 36px;
}

.node-type-newsletter .pane-node-body {
  margin-bottom: 40px;
}

.node-type-newsletter .pane-node-field-news-posts {
  border: 1px solid #dddddd;
  margin-bottom: 20px;
}

.node-type-newsletter .pane-node-field-news-posts .panel-pane {
  padding-left: 40px;
  padding-right: 40px;
}

.node-type-newsletter .pane-node-field-news-posts .newsletter-header {
  color: #fff;
  padding: 12px 40px 10px 40px;
  margin-bottom: 28px;
}

.node-type-newsletter .pane-node-field-news-posts .newsletter-header.current {
  background: #b31b1b;
}

.node-type-newsletter .pane-node-field-news-posts .newsletter-header.past {
  background: #555555;
}

.node-type-newsletter .pane-node-field-news-posts .node-news-post {
  margin-bottom: 36px;
}

.node-type-newsletter .pane-node-field-news-posts .node-news-post .pane-node-created {
  color: #666666;
  text-transform: uppercase;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 12px;
}

.node-type-newsletter .pane-node-field-news-posts .node-news-post .pane-node-body {
  margin-bottom: initial;
}

.node-type-newsletter .pane-node-field-news-posts .node-news-post .hero-image {
  text-align: right;
}

.node-type-newsletter .pane-node-field-news-posts .node-news-post .hero-image > * {
  max-width: 100%;
}

.node-type-newsletter .pane-node-field-news-posts .pane-node-field-news-category .links li {
  display: inline;
  margin-right: 10px;
}

.node-type-newsletter .pane-node-field-news-posts .pane-node-field-news-category .links li a {
  color: #fff;
  background: #cf4520;
  padding: 3px 14px 4px 14px;
  border-radius: 15px;
  border-bottom: none;
  font-size: 14px;
}

.node-type-newsletter .pane-newsletters-panel-pane-1 {
  margin-bottom: 20px;
  padding: 20px 40px 20px 40px;
  background-color: #f7f7f7;
  border: 1px solid #dddddd;
}

.node-type-newsletter .pane-newsletters-panel-pane-1 .view-newsletters {
  margin-left: 1em;
  list-style: none;
}

.node-type-newsletter .pane-newsletters-panel-pane-1 .view-newsletters .views-row {
  display: list-item;
  margin-left: .5em;
}

.node-type-newsletter .pane-newsletters-panel-pane-1 .view-newsletters .views-row .field-content:before {
  content: "\2022 ";
  font-weight: bold;
  padding-right: .5em;
}

.pane-newsletters-panel-pane-2 {
  text-align: center;
}

.pane-newsletters-panel-pane-2 .view {
  color: #fff;
  background-color: #cf4520;
  padding: 1rem 0;
}

.pane-newsletters-panel-pane-2 .view .view-header {
  font-weight: bold;
  font-size: 18px;
}

.pane-newsletters-panel-pane-2 .view a {
  color: #fff;
}

.not-front.logged-in.page-admin .panels-dnd .container {
  max-width: 100%;
}

.view-alerts .views-exposed-form {
  padding-left: 0;
  padding-right: 0;
}

.view-alerts .views-exposed-form .views-exposed-widget {
  width: 99%;
}

@media screen and (min-width: 992px) {
  .view-alerts .views-exposed-form .views-exposed-widget {
    width: 40%;
  }
}

.view-alerts .view-content .views-label {
  font-weight: bold;
}

.view-alerts .view-content .views-field-field-alert-type a {
  color: #fff;
  background-color: #cf4520;
  display: inline-block;
  padding: .1rem 1.25rem .2rem 1.25rem;
  border-radius: 1.5rem;
  margin-top: 1rem;
  font-size: 13px;
}

.page-node.node-type-alert .pane-bundle-quick-links {
  margin-bottom: 3rem;
}

.page-node.node-type-alert .pane-bundle-quick-links ul {
  list-style: none;
}

.page-node.node-type-alert .pane-bundle-quick-links ul li {
  margin-left: 0;
}

.page-node.node-type-alert .field-label {
  display: inline;
  font-weight: bold;
}

.page-node.node-type-alert .pane-node-field-alert-type {
  background-color: #f7f7f7;
  padding: 2rem 2rem 0rem 2rem;
}

.page-node.node-type-alert .pane-node-field-alert-type .field-alert-type {
  color: #fff;
  background-color: #cf4520;
  display: inline-block;
  padding: .1rem 1.25rem .2rem 1.25rem;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
}

.page-node.node-type-alert .pane-node-field-related-services {
  background-color: #f7f7f7;
  padding: 0rem 2rem 1rem 2rem;
}

.page-node.node-type-alert .pane-node-field-related-services .pane-title {
  display: inline-block;
  font-weight: bold;
}

.page-node.node-type-alert .pane-node-field-related-services .related-services {
  list-style-type: none;
  display: inline-block;
  padding: 0;
}

.page-node.node-type-alert .pane-node-field-related-services .related-services li {
  display: inline;
}

.page-node.node-type-alert .pane-node-field-related-services .related-services li:not(:last-child)::after {
  content: ", ";
}

.page-node.node-type-alert .pane-node-updated .pane-title {
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .page-node.node-type-alert .pane-node-updated {
    padding-left: 4rem;
  }
}

@media screen and (min-width: 992px) {
  .page-node.node-type-alert .pane-node-field-alert-inception {
    padding-left: 4rem;
  }
}

@media screen and (min-width: 992px) {
  .page-node.node-type-alert .pane-node-field-alert-expiration {
    padding-left: 4rem;
  }
}
