/**
 * Audience Panes
 *
 */

.audience a {
  display: block;
  padding: 1em;
  border: 1px solid #ddd;
  text-decoration: none;
  border-radius: 20px;
  margin-bottom: 5px;
  padding: 10px 20px;
  &:after {
    float: right;
    margin: 0 5px 0 0;
    @include fontello-its();
    font-size: 24px;
  }
}


/**
 * Audience Banner
 *
 */

.audience-banner {
  width: 50%;
  white-space: nowrap;

  > *{
    white-space: unset;
  }

  @include breakpoint($md) {
    width: 33%;
  }
}

.audience-banner a {
  text-decoration: none;
  border: none;
}

.audience-banner a:before {
  padding: 0 0 5px;
  color: $wcm-bright-orange;
  font-size: 46px;
  @include fontello-its();
  width: 100%;
}

.audience-banner__title {
  text-align: center;
}

/**
 * Audience Teasers
 *
 */

.audience-teaser {
  border-bottom: 1px solid #efefef;
  margin-bottom: 15px;
  padding-top: 20px;
}

.audience-teaser:first-child {
  border-top: 1px solid #efefef;
  padding: 30px 0 20px;
  margin-top: 25px;
}

.audience-icon {
  color: $wcm-bright-orange;
  font-size: 36px;
  @include fontello-its();
  @include breakpoint($sm) {
    float: left;
    margin: -5px 15px 0 0;
  }
}

.audience-teaser__title {
  font-size: 20px;
  margin: 0 0 15px;
}

.audience-teaser__description {
  h3 {
    color: $wcm-dark-gray;
    font-size: 18px;
  }
}

/**
 * Audience Teasers - Service Index
 *
 */

.ui-tabs .audience-teaser:first-child {
  border-top: none;
  padding-top: 0;
}

/**
 * Audience Services
 *
 */

.pane-services-panel-pane-4 {
  .pane-title {
    border-top: 1px solid #efefef;
    font-size: 20px;
    padding-top: 30px;
    color: $wcm-red;
  }
  h3 {
    margin-top: 20px;
    color: $wcm-dark-gray;
    font-size: 18px;
  }
}

// Homepage Service Audience pane
.pane-audience-panel-pane-3{
  padding-top: 1rem;
  padding-bottom: 3rem;

  h3.pane-title{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .view-content{

    .audience-cta{

      @include breakpoint($sm) {
        display: inline-block;
        width: 49%;
      }

      @include breakpoint($md) {
        width: 32.5%;
      }

      .wcm-cta{
        &__tile{
          z-index: initial;
          float: none;
          width: auto;
          height: auto;
          border: none;
          padding: 3rem 1rem;
          margin: 5rem auto;
          width: 80%;

          @include breakpoint($md) {
            margin: 2.5rem auto;
          }

          &::after{
            opacity: 1;
          }

          &:hover {
              z-index: 100;
              border: none;
          }
        }

        &__detail{
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          width: auto;
          font-size: unset;

          .cta-icon{
              width: 35px;
              height: auto;
              max-height: 35px;
              margin-bottom: 1rem;
          }

        }

      }

    }
  }

}

/**
 * Audience Nav
 *
 */

.audience-nav {
  margin-bottom: 30px;
  @include clearfix();
}

.audience-nav__icon {
  @include breakpoint($sm) {
    float: left;
    width: 20%;
  }
}

.audience-nav__icon a {
  &:hover {
    text-decoration: none;
  }
}

.audience-nav__title {
  @include breakpoint($sm) {
    float: right;
    width: 80%;
  }
}

/**
 * Audience Icons
 *
 */

.audience-nav__icon a:before {
  color: $wcm-bright-orange;
  display: none;
  @include breakpoint($sm) {
    display: block;
    float: left;
    @include fontello-its();
    font-size: 30px;
  }
}

// Administrators
.audience-banner--5 a:before,
.audience-5-icon:before,
.audience-nav--5 .audience-nav__icon a:before {
  content: '\e831';
}

// Faculty
.audience-banner--1 a:before,
.audience-1-icon:before,
.audience-nav--1 .audience-nav__icon a:before {
  content: '\e807';
}

// IT Professionals
.audience-banner--4 a:before,
.audience-4-icon:before,
.audience-nav--4 .audience-nav__icon a:before  {
  content: '\e80b';
}

// Researchers
.audience-banner--157 a:before,
.audience-157-icon:before,
.audience-nav--157 .audience-nav__icon a:before  {
  content: '\e80a';
}

// Staff
.audience-banner--2 a:before,
.audience-2-icon:before,
.audience-nav--2 .audience-nav__icon a:before  {
  content: '\e821';
}

// Students
.audience-banner--3 a:before,
.audience-3-icon:before,
.audience-nav--3 .audience-nav__icon a:before  {
  content: '\e800';
}

// Visitors
.audience-banner--6 a:before,
.audience-6-icon:before,
.audience-nav--6 .audience-nav__icon a:before  {
  content: '\e805';
}
